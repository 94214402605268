/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
const IconLSJ = ({
                   height = '119',
                   width = '127',
                   className = '',
                 }) => (<svg className={`lsj-sachsen-icon-36-34 ${className}`} version="1.1" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 127 119">
<path d="M0 0 C3.19161493 2.6596791 4.96351309 5.65884264 5.50488281 9.84667969 C5.57962327 11.96831218 5.56178464 14.06558378 5.5 16.1875 C5.49484375 16.91517578 5.4896875 17.64285156 5.484375 18.39257812 C5.40493437 23.72006563 5.40493437 23.72006563 4.25 24.875 C2.70044441 24.97399523 1.14646327 25.00579524 -0.40625 25.0078125 C-1.81583984 25.00974609 -1.81583984 25.00974609 -3.25390625 25.01171875 C-4.24261719 25.00785156 -5.23132813 25.00398438 -6.25 25 C-7.23871094 25.00386719 -8.22742188 25.00773437 -9.24609375 25.01171875 C-10.18582031 25.01042969 -11.12554687 25.00914063 -12.09375 25.0078125 C-12.96193359 25.00668457 -13.83011719 25.00555664 -14.72460938 25.00439453 C-16.75 24.875 -16.75 24.875 -17.75 23.875 C-17.95824525 20.96254147 -18.07476741 18.10327331 -18.125 15.1875 C-18.16238281 14.38376953 -18.19976562 13.58003906 -18.23828125 12.75195312 C-18.30040179 8.53120742 -18.09909187 5.72635896 -15.4140625 2.35546875 C-10.89662588 -1.85064892 -5.71032072 -2.33604029 0 0 Z " fill="000000" transform="translate(114.75,85.125)"/>
<path d="M0 0 C3.1786241 2.5699514 4.70728109 4.12184327 6 8 C6.07973476 9.33525717 6.10771338 10.67412083 6.09765625 12.01171875 C6.09443359 12.76904297 6.09121094 13.52636719 6.08789062 14.30664062 C6.07951172 15.09232422 6.07113281 15.87800781 6.0625 16.6875 C6.05798828 17.48478516 6.05347656 18.28207031 6.04882812 19.10351562 C6.0370585 21.06904257 6.01912348 23.03453085 6 25 C2.35418313 25.02887512 -1.29160463 25.04675585 -4.9375 25.0625 C-6.49887695 25.07506836 -6.49887695 25.07506836 -8.09179688 25.08789062 C-9.08115234 25.09111328 -10.07050781 25.09433594 -11.08984375 25.09765625 C-12.46450806 25.10551147 -12.46450806 25.10551147 -13.86694336 25.11352539 C-16 25 -16 25 -17 24 C-17.18139888 21.25522679 -17.27824606 18.55966588 -17.3125 15.8125 C-17.34150391 15.05646484 -17.37050781 14.30042969 -17.40039062 13.52148438 C-17.46039509 8.19954949 -16.52567604 5.22042252 -13 1 C-8.89070032 -1.6253859 -4.54650121 -1.44202159 0 0 Z " fill="000000" transform="translate(69,27)"/>
<path d="M0 0 C5.39616218 2.03628761 5.39616218 2.03628761 7.15356445 4.84106445 C8.06379735 7.1627228 8.24450824 8.80963127 8.23046875 11.29296875 C8.22853516 12.08767578 8.22660156 12.88238281 8.22460938 13.70117188 C8.20624023 14.9319043 8.20624023 14.9319043 8.1875 16.1875 C8.18685547 17.01958984 8.18621094 17.85167969 8.18554688 18.70898438 C8.14043257 24.85956743 8.14043257 24.85956743 7 26 C5.43827255 26.08685603 3.87270656 26.10702219 2.30859375 26.09765625 C1.36435547 26.09443359 0.42011719 26.09121094 -0.55273438 26.08789062 C-2.04256836 26.07532227 -2.04256836 26.07532227 -3.5625 26.0625 C-4.55958984 26.05798828 -5.55667969 26.05347656 -6.58398438 26.04882812 C-9.05604849 26.03700007 -11.52799518 26.02051849 -14 26 C-14.14584064 22.77131254 -14.23424106 19.54347635 -14.3125 16.3125 C-14.35439453 15.40048828 -14.39628906 14.48847656 -14.43945312 13.54882812 C-14.56351709 6.72530993 -14.56351709 6.72530993 -11.60546875 2.91796875 C-7.47705807 -0.12108619 -5.15786685 -0.34385779 0 0 Z " fill="000000" transform="translate(21,84)"/>
<path d="M0 0 C3.78151982 0.6648826 7.25726602 1.72204722 10.875 3 C15.28079618 4.48473717 19.33945306 5.64826061 24 6 C24.63679688 6.0515625 25.27359375 6.103125 25.9296875 6.15625 C35.41525218 6.5731979 44.59581589 4.24369693 53 0 C53.99 0 54.98 0 56 0 C56.66 1.65 57.32 3.3 58 5 C41.39301397 12.36214947 24.6277074 15.09000418 7 9 C3.8575795 7.52740127 0.90386454 5.89839206 -2 4 C-1.34 2.68 -0.68 1.36 0 0 Z " fill="000000" transform="translate(35,103)"/>
<path d="M0 0 C12.90443503 3.19111842 24.74672193 9.21381197 32.11230469 20.74072266 C38.53354759 31.81780728 38.53354759 31.81780728 38 38 C35.03 38.495 35.03 38.495 32 39 C31.79246094 38.23816406 31.58492187 37.47632813 31.37109375 36.69140625 C27.96634254 25.28050365 22.14562597 17.35046633 12 11 C7.73576401 8.82701841 3.4088511 6.86030348 -1 5 C-0.67 3.35 -0.34 1.7 0 0 Z " fill="000000" transform="translate(78,24)"/>
<path d="M0 0 C0.495 2.475 0.495 2.475 1 5 C-0.03898437 5.47308594 -1.07796875 5.94617188 -2.1484375 6.43359375 C-15.79336211 12.82197382 -25.29444718 19.451893 -31 34 C-31.3668216 35.32451153 -31.715739 36.65534965 -32 38 C-33.98 38 -35.96 38 -38 38 C-39 36 -39 36 -38.14453125 33.390625 C-34.75268031 25.39341633 -31.42861491 18.98010689 -25 13 C-24.49726563 12.5153125 -23.99453125 12.030625 -23.4765625 11.53125 C-17.57595859 6.10158631 -8.28355696 0 0 0 Z " fill="000000" transform="translate(49,24)"/>
<path d="M0 0 C2.86125133 1.27166726 4.48787391 2.32492471 6.375 4.8125 C7.26089715 7.91314004 6.89945179 9.91616529 6 13 C4.29577324 15.62734958 2.95485503 16.71862637 -0.0546875 17.60546875 C-4.41855155 18.34747257 -6.47526116 18.27173976 -10.5 16.1875 C-13.37870979 13.66862893 -13.8767976 12.92401803 -14.375 9.1875 C-13.87761245 4.95970586 -12.20618923 3.67182436 -9 1 C-5.93570224 -0.53214888 -3.3834358 -0.22938548 0 0 Z " fill="000000" transform="translate(112,65)"/>
<path d="M0 0 C3.0625 2.125 3.0625 2.125 5 5 C5.07219484 9.11510561 4.674788 11.17284052 2.0625 14.375 C-1.68064894 17.32414765 -4.25141761 17.32193779 -9 17 C-12.5386994 15.34860695 -13.81070153 14.28394771 -16 11 C-15.85679006 7.06172672 -15.59129356 4.74897184 -13.125 1.625 C-8.96311786 -1.55761576 -4.87542936 -1.36322685 0 0 Z " fill="000000" transform="translate(69,8)"/>
<path d="M0 0 C2.87305972 1.40460697 4.74510999 2.74510999 7 5 C7.4375 8.3125 7.4375 8.3125 7 12 C4.05020446 15.57080513 1.89955225 17.78414179 -2.75 18.3125 C-7.03275809 17.90069634 -9.95798787 16.04201213 -13 13 C-13.59259259 5.77037037 -13.59259259 5.77037037 -11.125 2.5625 C-7.29495887 -0.25370672 -4.76033254 -0.32273441 0 0 Z " fill="000000" transform="translate(21,65)"/>
<path d="M0 0 C3.91121498 0.68936071 7.55508596 1.78250181 11.3125 3.0625 C12.38113281 3.42472656 13.44976562 3.78695312 14.55078125 4.16015625 C15.76314453 4.57587891 15.76314453 4.57587891 17 5 C16.01 5.33 15.02 5.66 14 6 C13.67 6.66 13.34 7.32 13 8 C12.34 7.34 11.68 6.68 11 6 C11.99 7.32 12.98 8.64 14 10 C7.68368172 9.59683075 3.35006016 7.28767869 -2 4 C-1.34 2.68 -0.68 1.36 0 0 Z " fill="000000" transform="translate(35,103)"/>
<path d="M0 0 C0.33 1.65 0.66 3.3 1 5 C-0.78953185 5.83790789 -2.58169695 6.67019396 -4.375 7.5 C-5.87160156 8.19609375 -5.87160156 8.19609375 -7.3984375 8.90625 C-10 10 -10 10 -12 10 C-12.66 8.35 -13.32 6.7 -14 5 C-12.56328432 4.35242375 -11.12559584 3.70700547 -9.6875 3.0625 C-8.88699219 2.70285156 -8.08648437 2.34320312 -7.26171875 1.97265625 C-2.6746988 0 -2.6746988 0 0 0 Z " fill="000000" transform="translate(49,24)"/>
<path d="M0 0 C1.58624235 0.42683239 3.1689098 0.86696158 4.75 1.3125 C6.07257813 1.67794922 6.07257813 1.67794922 7.421875 2.05078125 C10.05772505 3.02125331 11.85407326 4.21005644 14 6 C13.34 6.66 12.68 7.32 12 8 C12 8.66 12 9.32 12 10 C10.58208592 9.54554036 9.16571954 9.08625056 7.75 8.625 C6.96109375 8.36976562 6.1721875 8.11453125 5.359375 7.8515625 C3.13372042 7.04826333 1.09068328 6.10288338 -1 5 C-0.67 3.35 -0.34 1.7 0 0 Z " fill="000000" transform="translate(78,24)"/>
<path d="M0 0 C0.66 0.33 1.32 0.66 2 1 C0.23900301 2.76099699 -1.52743009 4.51317522 -3.3125 6.25 C-5.9061907 8.90400909 -8.24685303 11.65824971 -10.5703125 14.546875 C-12 16 -12 16 -15 17 C-12.25275065 8.9871894 -6.58352928 4.88137748 0 0 Z " fill="000000" transform="translate(31,31)"/>
<path d="M0 0 C1.32 1.65 2.64 3.3 4 5 C3.01 5.33 2.02 5.66 1 6 C-0.20882096 8.00016466 -0.20882096 8.00016466 -1 10 C-5 4.25 -5 4.25 -5 2 C-3.68 2 -2.36 2 -1 2 C-0.67 1.34 -0.34 0.68 0 0 Z " fill="000000" transform="translate(108,43)"/>
</svg>);

export default IconLSJ;

IconLSJ.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
};
